import { DataFlow, DataFlowConfiguration, DataFlowRequest } from '@coac-gmbh/saifty-main-apis'
import { SDS_TREE } from './sds'
import { SDS_PASSPORT_ID } from './passports'

export interface FlowData {
  extract: {
    quickExtraction: boolean
    allEchaIngredients: boolean
    allEPAIngredients: boolean
    passportId: string
    displayAllPassportItems: boolean
    sections?: string[]
    languages: string[]
    ocr: boolean
  }
  validate: {
    confidence: number
    lookup_phrases: boolean
    echa_reach_call: boolean
    epa_call: boolean
  }
  store: {
    dataassetId: string
    dataassetName: string
  }
}

export interface DataFlowConfigurationParsed extends DataFlowConfiguration {
  data: FlowData
}

// Custom DataFlow structure for the form
export interface FlowWithData extends DataFlowRequest {
  data: FlowData
}

export interface FlowWithControls extends DataFlow {
  selected?: boolean
  isSharedToMe: boolean
  allowUploadFiles?: boolean
  isSendingFiles?: boolean
  filesToUpload: File[]
  latestConfiguration: { data: FlowData; version: string }
}

export interface FileAlreadyUploaded {
  id: string
  file: File
  name: string
  isUploading: boolean
  isUploaded: boolean
  dataFlowId: string
  approvalId?: string
  dataAssetId?: string
  dataObjectId?: string
}

export const DEFAULT_FLOW_TYPE = 'SDS Extraction'

export const TECHNICAL_DATA_SHEET_FLOW_TYPE = 'TDS Extraction'

export const DEFAULT_FLOW_CONFIDENCE = 95

export const DEFAULT_FLOW_LANGUAGE = 'en'

export type SUPPORTED_LANGUAGES_TYPE = 'EN' | 'DE' | 'FR' | 'ES' | 'NL' | 'ZH'

export const SUPPORTED_LANGUAGES_OPTIONS = [
  { name: 'English: en', value: 'en' },
  { name: 'German: de', value: 'de' },
  { name: 'French: fr', value: 'fr' },
  { name: 'Spanish: es', value: 'es' },
  { name: 'Dutch: nl', value: 'nl' },
  { name: 'Chinese: zh', value: 'zh' },
]

export const FLOW_FALLBACK_DATA: FlowData = {
  extract: {
    allEchaIngredients: false,
    allEPAIngredients: false,
    passportId: SDS_PASSPORT_ID,
    displayAllPassportItems: false,
    languages: SUPPORTED_LANGUAGES_OPTIONS.map((lang) => lang.value),
    quickExtraction: false,
    ocr: false,
  },
  validate: {
    confidence: DEFAULT_FLOW_CONFIDENCE,
    lookup_phrases: true,
    echa_reach_call: false,
    epa_call: false,
  },
  store: {
    dataassetId: '',
    dataassetName: '',
  },
}

export const FLOW_FALLBACK_DATA_SECTIONS = SDS_TREE.map((section) => section.id)

export const FLOW_FALLBACK: FlowWithData = {
  name: 'Extraction Fallback',
  flowType: DEFAULT_FLOW_TYPE,
  data: FLOW_FALLBACK_DATA,
}
