import { PassportPublic, DataObjectInDB, DataObjectCreate } from '@coac-gmbh/saifty-query-builder/dist/models'

export interface QueryBuilderManager extends PassportPublic {
  isSelected: boolean
}

export interface QueryComponentWithControls extends DataObjectInDB {
  isSelected: boolean
}

export enum QueryTypes {
  EHS = 'EHS',
  KVS = 'KVS',
}

export function getDefaultEHSComponentData(): DataObjectCreate {
  return {
    name: '',
    operation: '',
    type: QueryTypes.EHS.toLowerCase(),
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          aggregate: '',
          system: [],
          identifier: {},
          rating: [],
          val_area: [],
          lang: [],
          attribute: [],
        },
      },
    ],
  }
}

export function getDefaultKVSComponentData(): DataObjectCreate {
  return {
    name: '',
    operation: '',
    type: QueryTypes.KVS.toLowerCase(),
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          source: '',
          aggregate: '',
          attribute: [],
          dataType: [],
          rating: [],
          val_area: [],
          lang: [],
        },
      },
    ],
  }
}
